import axios from 'axios';

import { baseURL } from 'config';
import { SKIP_ERROR_POPUP } from 'constants/apiError';
import { getCookie } from 'utils/cookie';

export default class API {
  static post = async function (endpoint, { headers, data, options, hasAuth = true } = {}) {
    const authorization = getCookie('Authorization');
    const terminalToken = getCookie('TerminalToken');

    const myAxios = axios;

    myAxios.interceptors.response.use(
      res => res,
      error => {
        const { config: { url } = {} } = error;

        if (!SKIP_ERROR_POPUP.includes(url?.substring(baseURL.length))) {
          const event = new Event('hasError');

          window.dispatchEvent(event);

          return error;
        }

        return { hasError: true };
      },
    );

    return await myAxios({
      data,
      method: 'POST',
      url: `${baseURL}${endpoint}`,
      headers: {
        ...(hasAuth && { Authorization: authorization, TerminalToken: terminalToken }),
        ...headers,
      },
      ...options,
    });
  };

  static get = function (endpoint, { headers, options, hasAuth } = {}) {
    const authorization = getCookie('Authorization');
    const terminalToken = getCookie('TerminalToken');
    return axios({
      method: 'GET',
      url: `${baseURL}${endpoint}`,
      headers: {
        ...headers,
        ...(hasAuth && { Authorization: authorization, TerminalToken: terminalToken }),
      },
      ...options,
    });
  };
}
